import React from "react";

import { Divider, Box} from '@mui/material';

export default function MyDivider() {
    return(
        <Box sx={{my: 2}}>
            <Divider />
        </Box>
    )
}