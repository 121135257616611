import React, {useState, useEffect} from "react";

import backend from '../../config/backend';
import {Box, Typography} from '@mui/material';
import ThemedLinkButton from './ThemedLinkButton';

export default function DownloadButtons(props) {
    const [pdfFilePath, setPdfFilePath] = useState(null);
    const [connected, setConnected] = useState(false);
    useEffect(() => {
        fetch(`${backend.url}/versions/mostRecent`).then(res => {
            if (res.ok) {
                return res.json()
            }
        })
        .then(jsonRes => {
            const mostRecentVersion = jsonRes[0].version_num;
            setPdfFilePath(`/openData/Das-Mathebuch-${mostRecentVersion}-`);
        })
        .catch(error => setConnected(false))
    }, []);

    useEffect(() => {
        if (pdfFilePath == null) {
            setConnected(false);
        } else {
            setConnected(true);
        }
    }, [pdfFilePath]);


    if (connected) {
        return(
            <div>
                <Box component="div" sx={{
                    display: 'flex', 
                    flexDirection: {
                        xs: 'column',
                        md: 'row'
                    },
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    width: '90%',
                    margin: 'auto'
                }}>
                    <ThemedLinkButton href={pdfFilePath + "dunkel.pdf"} annotation="dunkel" type="dark" />
                    <ThemedLinkButton href={pdfFilePath + "hell.pdf"} annotation="hell" type="light" />
                    <ThemedLinkButton href={pdfFilePath + "schwarzWeiss.pdf"} annotation="schwarz-weiß" type="blackNwhite" />
                </Box>
            </div>
        )
    } else {
        return(
            <Typography>Es konnte keine Verbindung hergestellt werden.</Typography>
        )
    }
}
