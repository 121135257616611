import React from 'react';

import { Box, Typography } from '@mui/material';
import DownloadButtons from '../downloads/DownloadButtons';
import VersionsTable from '../downloads/VersionsTable';

export default function MainPage() {
    return(
        <div>
            <Typography variant="h2">Das Mathebuch herunterladen</Typography>
            <Typography variant="body1">
              Hier kannst du die aktuelle Version des Mathebuchs in verschiedenen Farbvarianten herunterladen.
            </Typography>

            <DownloadButtons />

            <Box sx={{marginTop: '30px'}}></Box>
            <Typography variant="h3">Alte Versionen</Typography>
            <Typography variant="body1">
              Alle bisher veröffentlichten Versionen des Mathebuchs findest du hier.
            </Typography>

            <VersionsTable/>
        </div>
    );
}