import React from 'react';

import { Typography, Box } from '@mui/material';

export default function NotFoundPage() {
    return(
        <Box>
            <Typography variant="h2">404 Seite nicht gefunden</Typography>
            <Typography variant="body1">Die aufgerufene Seite gibt es nicht.</Typography>
            <Typography variant="body1">Zurück zur Startseite: <a href="/">Startseite</a></Typography>
        </Box>
    )
}   