import React from "react";

import { Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

export default function ThemedLinkButton(props) {
    const text = props.annotation;

    return(
        <Button 
            variant="contained" 
            color="primary" 
            size="large"
            className={`themedButton ${props.type}`}
            startIcon={
                (props.type === "light" && <LightModeIcon/>) ||
                (props.type === "dark" && <DarkModeIcon/>) ||
                (props.type === "blackNwhite" && <PrintIcon/>)
            } 
            href={props.href}
            target='_blank'
            >
                {text}
        </Button>
    )
    }