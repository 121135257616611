import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import { IconButton, Tooltip, useMediaQuery, SwipeableDrawer, Box, List, ListItemButton, ListItemIcon, ListItemText, ListItem } from '@mui/material';
import { ColorModeContext } from '../../context/ColorModeContext';
import MyDivider from '../page-structure/MyDivider';

import HomeIcon from '@mui/icons-material/Home';
import DownloadIcon from '@mui/icons-material/Download';
import ForumIcon from '@mui/icons-material/Forum';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from "@mui/icons-material/Close";

export default function NavBarItems() {
    const colorMode = React.useContext(ColorModeContext);
    const theme = useTheme();
    const themeMode = theme.palette.mode;
    const screenIsTooSmallForItemsNextTooAnother = useMediaQuery(theme.breakpoints.down('sm'));

    const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
    const toggleSideMenu = (newOpen) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setSideMenuIsOpen(newOpen);
    };

    const mainPageOption = {
        text: "Zurück zur Startseite",
        icon: <HomeIcon />,
        href: '/',
        onClick: () => {},
        key: 0
    }

    const downloadOption = {
        text: 'Das Mathebuch herunterladen',
        icon: <DownloadIcon />,
        href: '/downloads',
        onClick: () => {},
        key: 1
    }
    const participationOption = {
        text: 'Neuigkeiten erhalten oder Feedback geben',
        icon: <ForumIcon />,
        href: '/participation',
        onClick: () => {},
        key: 2
    }
    const darkModeOption = {
        text: themeMode === 'dark' ? "Wechsel zum hellen Modus" : "Wechsel zum dunklen Modus",
        icon: themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />,
        href: '',
        onClick: colorMode.toggleColorMode,
        key: 3
    }
    const options = [mainPageOption, downloadOption, participationOption, darkModeOption]; 

    const horizontalToolBarItemsStyle = {
        width: 50*options.length + 'px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'space-between',
    }

    if (!screenIsTooSmallForItemsNextTooAnother) {
        return(
            <Box sx={horizontalToolBarItemsStyle}>
                {options.map((option) => {
                    return(
                        <Tooltip title={option.text} key={option.key}>
                            <IconButton href={option.href} onClick={option.onClick}>
                                {option.icon}
                            </IconButton>
                        </Tooltip>
                    )
                })}
            </Box>
        )
    } else {
        return(
            <Box>
                <Tooltip title="Öffne Seitenmenü">
                    <IconButton onClick={toggleSideMenu(true)}>
                        <MenuIcon />
                    </IconButton>
                </Tooltip>
                <SwipeableDrawer
                    anchor='right'
                    variant='temporary'
                    open={sideMenuIsOpen}
                    onClose={toggleSideMenu(false)}
                    onOpen={toggleSideMenu(true)}
                    disableSwipeToOpen={false}
                    disableBackdropTransition={true}
                >
                    <Box sx={{ p: 2 }}>
                        <Tooltip title="Seitenmenü schließen">
                            <IconButton onClick={toggleSideMenu(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                        <MyDivider />
                        <List>
                            {options.map((option) => {
                                return(
                                    <ListItem disablePadding key={option.key}>
                                        <ListItemButton href={option.href} onClick={option.onClick}>
                                            <ListItemIcon>
                                                {option.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={option.text} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Box>
                </SwipeableDrawer>
            </Box>
        )
    }
}