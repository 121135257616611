import React, {useState, useEffect} from 'react';

import backend from '../../config/backend';
import { Typography } from '@mui/material';
import BlogPostsOverview from '../blog/BlogPostsOverview';
import ParticipationDescription from '../participation/ParticipationDescription';

export default function MainPage() {
    const [blogPosts, setBlogPosts] = useState(null);
    const [connected, setConnected] = useState(false);
    useEffect(() => {
        fetch(`${backend.url}/blog`).then(res => {
            if (res.ok) {
                return res.json()
            }
        })
        .then(jsonRes => setBlogPosts(jsonRes.reverse()))
        .catch(error => setConnected(false))
    }, []);

    useEffect(() => {
        if (blogPosts == null) {
            setConnected(false);
        } else {
            setConnected(true);
        }
    }, [blogPosts]);
  
    if (connected) {
        return(
            <div>
                <Typography variant="h2">Was ist das Mathebuch?</Typography>
                <Typography variant="body1">
                    Das Mathebuch ist ein Projekt zur Förderung der mathematischen Bildung.
                    Das Ziel ist es, ein Buch zu entwickeln, dass die Grundlagen der Mathematik
                    ausführlich und auf anschauliche Weise erklärt, sodass sie für möglichst
                    viele Menschen verständlich werden.
                </Typography>
    
                <Typography variant="h3">Aktuelle Version herunterladen</Typography>
                <Typography variant="body1">
                    Hier kannst du die aktuelle Version des Buches herunterladen: <a href='/downloads/'>Buch herunterladen</a>.
                </Typography>

                <Typography variant="h3">Neuigkeiten erhalten oder Feedback geben</Typography>
                <ParticipationDescription />

                <Typography variant="h3">Was gibt es Neues?</Typography>   
                <BlogPostsOverview blogPosts={blogPosts} />
            </div>
        );
    } else {
        return(
              <Typography>Verfügbare Posts konnten nicht geladen werden.</Typography>
        )
    }
}