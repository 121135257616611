import React, { useState, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppBar, Toolbar, Typography } from '@mui/material';

import './navbar.scss';
import Logo from './Logo.js';
import NavBarItems from './NavBarItems.js';

function NavBar() {
    const theme = useTheme();
    const [scrolled, setScrolled] = useState(false);
    const isBigDevice = useMediaQuery(theme.breakpoints.up('md'));

    useEffect (() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    const handleScroll = () => {
        let scrollTop = window.scrollY;

        if (scrollTop > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }  
    };

    return(
        <AppBar position="fixed" color='transparent'>
            <Toolbar>
                <Logo/>

                <div className="toolbarTitles">
                    <Typography variant={(scrolled || !isBigDevice) ? "h3" : "h1"} component="h1">Das Mathebuch</Typography>
                    {(!scrolled && isBigDevice) && <Typography variant="h5" component="h2">für alle, die es endlich mal verstehen wollen</Typography>}
                </div>

                <NavBarItems/>
            </Toolbar>
        </AppBar>
    );
}

export default NavBar;