import React from 'react';

import { Typography } from '@mui/material';

import ParticipationDescription from '../participation/ParticipationDescription';

export default function ParticipationPage() {
    return(
        <div>
            <Typography variant="h2">Neuigkeiten erhalten oder Feedback geben</Typography>
            <ParticipationDescription />
        </div>
    )
}