import React, {useState, useEffect} from "react";

import backend from '../../config/backend';
import { useTheme } from '@mui/material/styles';
import { Paper, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, styled } from "@mui/material";
import { getDefaultDateStringFromUTCSTring } from "../../utils/dates";

export default function VersionsTable() {
    const theme = useTheme();
    const [versionInfo, setVersionInfo] = useState(null);
    const [connected, setConnected] = useState(false);
    useEffect(() => {
        fetch(`${backend.url}/versions`).then(res => {
            if (res.ok) {
                return res.json()
            }
        })
        .then(jsonRes => setVersionInfo(jsonRes.reverse()))
        .catch(error => setConnected(false))
    }, []);

    useEffect(() => {
        if (versionInfo == null) {
            setConnected(false);
        } else {
            setConnected(true);
        }
    }, [versionInfo]);

    if (connected) {
        return(
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: theme.palette.mode === 'light' ? 'grey.200' : 'grey.800'}}>
                            <TableCell>Version</TableCell>
                            <TableCell>Download</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {versionInfo.map((versionEntry) => {
                            const incompletePdfFileName = `Das-Mathebuch-${versionEntry.version_num}`;
                            const incompletePdfFileNamePath = `/openData/${incompletePdfFileName}`
                            return (
                                <TableRow key={versionEntry.id}>
                                    <TableCell>{versionEntry.version_num} ({getDefaultDateStringFromUTCSTring(versionEntry.released_at)})</TableCell>
                                    <TableCell>
                                        <a href={incompletePdfFileNamePath + `-dunkel.pdf`} target="_blank" rel="noreferrer">{incompletePdfFileName + `-dunkel.pdf`}</a><br/>
                                        <a href={incompletePdfFileNamePath + `-hell.pdf`} target="_blank" rel="noreferrer">{incompletePdfFileName + `-hell.pdf`}</a><br/>
                                        <a href={incompletePdfFileNamePath + `-schwarzWeiss.pdf`} target="_blank" rel="noreferrer">{incompletePdfFileName + `-schwarzWeiss.pdf`}</a><br/>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    } else {
        return(
            <Typography>Verfügbare Versionen konnten nicht geladen werden.</Typography>
        )
    }
}