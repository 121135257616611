import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Box, Card } from '@mui/material';

import NavBar from './components/navBar/NavBar';
import MainPage from './components/pages/MainPage';
import DownloadPage from './components/pages/DownloadPage';
import NotFoundPage from './components/pages/NotFoundPage';
import ParticipationPage from './components/pages/ParticipationPage';


function App() {
  return (
    <Router>
      <Box component="header">
        <NavBar/>
      </Box>
      <Card className="mainCard" component="main">
        <Switch>
          <Route path="/" exact>
            <MainPage />
          </Route>
          <Route path="/downloads" exact>
            <DownloadPage />
          </Route>
          <Route path="/participation" exact>
            <ParticipationPage />
          </Route>
          <Route path="/">
            <NotFoundPage />
          </Route>
        </Switch>
      </Card>
    </Router>
  );
}

export default App;
