import React from 'react';

import { getDefaultDateStringFromUTCSTring } from '../../utils/dates';
import { Typography, Card, CardHeader, CardContent, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function BlogPostsOverview(props) {
    const theme = useTheme();
    const cardStyle = {
        backgroundColor: theme.palette.mode === 'light' ? 'grey.200' : 'grey.800'
    }

    return(
        <Stack spacing={2}>
            {props.blogPosts.map((post) => {
                const date = getDefaultDateStringFromUTCSTring(post.written_at);
                return(
                    <Card
                        key={post.id}
                        variant="contained"
                        sx = {cardStyle}
                    >
                        <CardHeader
                            title={post.title}
                            subheader={date}
                        />
                        <CardContent>
                            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: post.content}} />
                        </CardContent>
                    </Card>
                )
            })}
        </Stack>
    ) 
}