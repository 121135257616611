import React from 'react';

import { Typography } from '@mui/material';

export default function ParticipationPage() {
    return(
        <Typography variant="body1">
            Möchtest du informiert werden, wenn es Neuigkeiten zum Mathebuch gibt?
            Du hast einen Themenwunsch, allgemeine Verbesserungsvorschläge oder einen Fehler entdeckt?
            Dann trete dem "Das Mathebuch"-Discord-Server bei: <a href="https://discord.gg/eUXgfyMyZB">Discord Server</a>. 
        </Typography>
    )
}