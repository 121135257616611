import React, { useState, useEffect } from 'react';
import logo from './logo-v1-5-500.png';
import { useTheme } from '@mui/material/styles';

function Logo () {
    const theme = useTheme();
    const [scrolledDown, setScrolledDown] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    const handleScroll = (e) => {
        let scrollTop = window.scrollY;

        if (scrollTop > 50 ) {
            setScrolledDown(true);
        } else {
            setScrolledDown(false);
        }
    };

    return(
        <a href="/">
            <div className={`${(scrolledDown) ? "smallNavBar" : ""}`}>
                <img src={logo} alt="Mathe für alle"/>
            </div>
        </a>
    );
}

export default Logo;