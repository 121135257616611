import React, { useState, useMemo, useEffect } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import App from './App';
import theme from './theme.js';
import { ColorModeContext } from './context/ColorModeContext.js';


export default function AppThemed() {    
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = useState(null);
    
    useEffect(() => {
      setMode(prefersDarkMode ? 'dark' : 'light');
  }, [prefersDarkMode]);

    const colorMode = useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
      }),
      [],
    );

    const getTheme = (mode) => createTheme(theme, {
      palette: {
        mode: mode,
        ...(mode === 'light'
          ? {
              // palette values for light mode
              // nothing to change as theme is defaulted to light mode
            }
          : {
              // palette values for dark mode
              background: {
                default: getComputedStyle(document.body).getPropertyValue("--gray"),
                paper: getComputedStyle(document.body).getPropertyValue("--gray"),
              },
              text: {
                primary: '#fff',
                secondary: 'rgba(255, 255, 255, 0.7)'
              }
            }),
      },
    });
    const themeWithColorMode = useMemo(() => getTheme(mode), [mode]);

    return(
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={themeWithColorMode}>
                <App />
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}