import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import './styles/Computer Modern/Sans/cmun-sans.css';

const green = getComputedStyle(document.body).getPropertyValue("--green");
const orange = getComputedStyle(document.body).getPropertyValue("--orange");
const gray = getComputedStyle(document.body).getPropertyValue("--gray");

const baseTheme = createTheme({
  palette: {
    primary: {
      main: green
    },
    secondary: {
      main: orange
    },
    divider: orange
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 670,
      md: 1000,
      lg: 1320,
      xl: 1950,
    },
  }
});

const secondary = baseTheme.palette.secondary.main;
const primary = baseTheme.palette.primary.main;

const themeWithoutTypography = createTheme(baseTheme, {
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: gray,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          transition: 'all 0.2s linear',
          '& img': {
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            display: 'block', // used to get rid of extra space
            transition: 'all 0.2s linear',
            '&:hover': {
                transform: 'scale(1.1)',
            },
            [baseTheme.breakpoints.up('xs')]: {
              width: '50px',
              height: '50px'
            },
            [baseTheme.breakpoints.up('md')]: {
              width: '100px',
              height: '100px'
            }
          },
          '& .smallNavBar img': {
            width: '50px',
            height: '50px'
          },
          '& .MuiTypography-root': {
            transition: 'all 0.2s linear'
          },
          '& .toolbarTitles': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          },
          '& .MuiSvgIcon-root': {
            color: secondary,
            '&:hover': {
              color: primary
            }
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: secondary,
            '&:hover': {
              color: primary
            }
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.themedButton': {
            margin: '0.5rem 0',
            border: '3px solid',
            [baseTheme.breakpoints.up('xs')]: {
              width: '250px'
            },
            [baseTheme.breakpoints.up('sm')]: {
              width: '300px'
            },
            [baseTheme.breakpoints.up('md')]: {
              width: '250px'
            },
            '&.dark': {
              backgroundColor: baseTheme.palette.grey[800],
              borderColor: secondary,
              color: secondary,
              '&:hover': {
                backgroundColor: baseTheme.palette.grey[900],
              },
              '& .MuiSvgIcon-root': {
                color: secondary
              }
            },
            '&.light': {
              backgroundColor: baseTheme.palette.common.white,
              borderColor: primary,
              color: primary,
              '&:hover': {
                backgroundColor: baseTheme.palette.grey[300],
              },
              '& .MuiSvgIcon-root': {
                color: primary
              }
            },
            '&.blackNwhite': {
              backgroundColor: baseTheme.palette.common.white,
              borderColor: baseTheme.palette.common.black,
              color: baseTheme.palette.common.black,
              '&:hover': {
                backgroundColor: baseTheme.palette.grey[300],
              }
            }
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            marginBottom: '0px',
            '&:hover': {
              color: primary
            }
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root': {
            border: '3px solid ' + secondary
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '&.mainCard': {
            maxWidth: '900px',
            margin: 'auto',   
            paddingTop: '3rem',  
            [baseTheme.breakpoints.up('xs')]: {
              marginTop: '3rem',
              marginBottom: '0rem',
              paddingLeft: '6%',
              paddingRight: '6%',
              paddingBottom: '6%'
            }, 
            [baseTheme.breakpoints.up('md')]: {
              marginTop: '9rem',
              marginBottom: '2rem',
              padding: '3rem'
            },
          }
        }
      }
    },
  }
})

const customTypography = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: [
      "Computer Modern Sans", "sans-serif"
    ].join(','),
    h1: {
      fontSize: '3.75rem',
      color: primary
    },
    h2: {
      fontSize: '2.75rem',
      color: primary,
      marginBottom: '1.5rem'
    },
    h3: {
      fontSize: '2rem',
      color: primary,
      marginTop: '1.5rem',
      marginBottom: '1rem'
    },
    h4: {
      fontSize: '1.5rem',
      color: primary
    },
    h5: {
      fontSize: '1.25rem',
      color: secondary
    },
    h6: {
      fontSize: '1rem',
      color: primary
    },
    body1: {
      marginBottom: '0.5rem'
    }
  },
}).typography;

// important to separate and have at the end otherwise the font does not work
let theme = createTheme(themeWithoutTypography, {
  typography: customTypography
});

theme = responsiveFontSizes(theme);

export default theme;